import { Toast } from "antd-mobile";
import _axios from "axios";

const axios = _axios.create({
  timeout: 10000, 
});
axios.interceptors.response.use(response => {
  if (response.status === 200) {
    const {data} = response;
    if (data.code !== 200){
      Toast.show({content:data.message});
    }
    return Promise.resolve(data.data)
  } else {
    return Promise.reject(response.data.msg)
  }
}, error => {
  if (error.response) {
    return Promise.reject(error)
  } else {
    return Promise.reject('请求超时, 请刷新重试')
  }
})

export default axios;
