import { Form, Button, Input, Stepper } from "antd-mobile";
import { useForm } from "rc-field-form";
import { useState } from "react";
import styles from "./computer.module.css";
export default function ParamForm(props) {
  const { onFinish, initValues } = props
  const [hiddenFlag,setHiddenFlag] = useState(parseInt(initValues['即送订单比例'])===1?true:false);
  const numberList = [{
    'key': '100万',
    'value': 1000000
  },
  {
    'key': '500万',
    'value': 5000000
  },
  {
    'key': '1000万',
    'value': 10000000
  },
  {
    'key': '3000万',
    'value': 30000000
  }];
  const jisong = [{
    'key': '全部即送',
    'value': 1.0
  },
  {
    'key': '即送:归还≈3:7',
    'value': 0.3
  }];
  const handleTagOnClick = (v,field) => {
    const values = form.getFieldsValue()
    values[field] = v
    form.setFieldsValue(values);
    if(field=='即送订单比例'){
      checkValue(v)
    }
  }
  const checkPercent = (_,v)=>{
    v = parseFloat(v)
    if(v>=0 && v<=1){
      return Promise.resolve()
    }
    return Promise.reject(new Error('百分比值必须在0-1之间'));
  }
  const checkValue = (v)=>{
    if(parseInt(v) ===1){
      setHiddenFlag(true);
    }
    else{
      setHiddenFlag(false);
    }
  }
  // <img block src={qrcode}></img>
  const [form] = useForm();
  return <Form
    form={form}
    onFinish={onFinish}
    initialValues={initValues}
    mode='card'
    footer={
      <Button block type='submit' color='primary' >
        立即模拟
      </Button>
    }
  >
    <Form.Item
      name='总投入资金'
      label='总投入资金(元)'
      rules={[{ required: true, message: '投入资金量不能为空' }]}
    >
      <Input type="number" placeholder='请输入金额，例如500000' />
    </Form.Item>
    <div className={styles['tag-list']}>
      {numberList.map(item => <span key={item.key} onClick={() => { handleTagOnClick(item.value,'总投入资金') }}>{item.key}</span>)}
    </div>
    <Form.Item
      name='租赁周期'
      label='租赁周期(月)'
    >
      <Stepper />
    </Form.Item>
    <Form.Item
      name='平均首付期数'
      label='平均首付期数(月)'
    >
      <Stepper />
    </Form.Item>
    <Form.Item
      name='预计运营时长'
      label='预计运营时长(月)'
      help='项目预计运营多少个月，改时长包含停量回款阶段，字段必须比租赁周期大'
    >
      <Stepper />
    </Form.Item>

    <Form.Item
      name='即送订单比例'
      label='即送订单比例(%)'
      help='发货订单中租赁套餐为租满即送的订单占全部发货订单的比例，即送比例越高意味着每个回款周期可回款金额越大'
      rules={[{ validator: checkPercent }]}
    >
      <Input onChange={checkValue}/>
    </Form.Item>
    <div className={styles['tag-list']}>
      {jisong.map(item => <span key={item.key} onClick={() => { handleTagOnClick(item.value,'即送订单比例') }}>{item.key}</span>)}
    </div>

    <Form.Item
      name='预期总体资金逾期率'
      label='总体资金逾期率(M2逾期,单位:%)'
      help='预期整体的资金逾期率(M2开始逾期,单位:%)'
      rules={[{ validator: checkPercent }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name='每单买断价'
      label='每单买断价'
      help='买断价为总租金+归还买断价，若是租满即送则买断价为总租金'
    >
      <Input />
    </Form.Item>
    <Form.Item
      name='每单采购成本'
      label='每单采购成本'
    >
      <Input />
    </Form.Item>
    <Form.Item
      name='每单运营成本'
      label='每单运营成本'
      help='比如锁机、快递等费用'
    >
      <Input />
    </Form.Item>
    <Form.Item
      name='预计每月发货量'
      label='预计每月发货量'
      help='资金充足时优先按预计发货量发货，资金不充足时按资金发'
    >
      <Input />
    </Form.Item>
    <Form.Item
      name='每单平台回款抽成比例'
      label='每单平台回款抽成比例(%)'
      help='平台抽成会在每笔回款的时候进行扣取，若按百分比计算人力成本或运营成本可自行通过该参数调整'
      rules={[{ validator: checkPercent }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name='租满归还租金占买断价比例'
      label='租满归还租金占买断价比例(%)'
      help='租满归还订单的租金是买断价的百分之多少'
      hidden={hiddenFlag}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name='租满归还套餐买断比例'
      label='租满归还套餐买断比例(%)'
      help='租满归还订单中到期买断的订单占比'
      hidden={hiddenFlag}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name='租满归还设备剩余残值'
      label='租满归还设备剩余残值(元)'
      help='租满归还订单到期后用户归还手机手机剩余残值'
      hidden={hiddenFlag}
    >
      <Input />
    </Form.Item>
  </Form >
}