import React from 'react';
import { Route, Routes, BrowserRouter, HashRouter } from "react-router-dom";
import { Computer} from './pages';


const App = () => (
  <BrowserRouter basename=''>
    <Routes>
      <Route path="/" Component={Computer} />
    </Routes>
  </BrowserRouter>
);

export default App;