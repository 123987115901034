import { Button, Card, Collapse } from "antd-mobile";
import { Table } from 'antd';
import { useState } from "react";
import ParamForm from "./ParamForm";
import styles from './computer.module.css';
import { __simulate } from "../../common/scripts/request";
import { getTableData } from "../../tools/transform";
//import { simulateResponceData } from "../../common/scripts/data";
import { pick } from 'lodash';
import qrcode from "../../images/qrcode.png";

export default function Computer() {
  const [component, setComponent] = useState('form');
  const [result, setResult] = useState([]);
  const initValues = {
    "总投入资金": 5000000,
    "平均首付期数": 1,
    "即送订单比例": 0.3,
    "归还订单比例": 0.7,
    "预计运营时长": 24,
    "租赁周期": 12,
    "停量月份": 13,
    "预期总体资金逾期率": 0.05,
    "每单买断价": 12375,
    "每单采购成本": 8180,
    "每单运营成本": 110,
    "预计每月发货量": 100,
    "每单平台回款抽成比例": 0.08,
    "租满归还租金占买断价比例": 0.44,
    "租满归还套餐买断比例": 0.95,
    "租满归还设备剩余残值": 5850,
    "租满归还订单租满买断价": 6930,
    "租满归还设备总租金": 5445,
    "租满归还每月租金": 453.75,
    "租满即送每月租金": 1031.25
  }

  const [values, setValues] = useState(initValues);
  const simulate = async (v) => {
    const data = await __simulate(v);
    setValues(v);
    setResult(data);
    setComponent('result');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  const importantKeys = ['月份', '当月实际发货量', '当月现金流', '当月剩余资金', 'key'];
  const totalKeys = ['结束可收回总资金', '累计盈利', '整体收益率', '投资年化收益率', '累计流水', '平台累计抽成', '累计逾期金额'];
  const onFinish = (values) => {
    simulate(values);
  };
  const handelResetParamOnClick = () => {
    setComponent('form');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const ComputerResult = (props) => {
    const columns = [
      {
        title: '月份',
        dataIndex: '月份',
        key: '月份',
      },
      {
        title: '发货量',
        dataIndex: '当月实际发货量',
        key: '当月实际发货量',
      },
      {
        title: '现金流',
        dataIndex: '当月现金流',
        key: '当月现金流"',
      },
      {
        title: '剩余资金',
        dataIndex: '当月剩余资金',
        key: '当月剩余资金',
      }
    ]
    return <div className={styles['result-pannel']}>

      <Collapse defaultActiveKey={['1', '2']}>
        <Collapse.Panel key='3' title='模拟参数'>
          <div className={styles['param-pannel']}>
            {Object.keys(props.paramData)?.map(item => <div className={styles.param}><span className={styles.label}>{item}</span><span>{props.paramData[item]}</span></div>)}
          </div>
        </Collapse.Panel>
        <Collapse.Panel key='2' title='模拟结果'>
          <div className={styles['param-pannel']}>
            {props.keys?.map(item => <div className={styles.param}><span className={styles.label}>{item}</span><span>{props.totalData[item]}</span></div>)}
          </div>
        </Collapse.Panel>

        <Collapse.Panel key='1' title='每月数据详情'>
          <Table dataSource={props.dataSource}
            columns={columns}
            className={styles.table}
            expandable={{
              expandedRowRender: (record) => moreDescription(record),
            }}
            pagination={false} />
        </Collapse.Panel>
      </Collapse>
      <Button color='primary' onClick={handelResetParamOnClick}>重新模拟</Button>
    </div>
  }
  const moreDescription = (record) => {
    const keys = Object.keys(record).filter(item => !importantKeys.includes(item));
    return <ul>
      {keys.map(item => {
        return <li key={item}>{`${item}：${record[item]}`}</li>
      })}
    </ul>
  }
  const Footer = () =>
    <Card className={styles.footer}>
      <img className={styles.qrcode} src={qrcode}></img>
      <div className={styles.title}>说明:</div>
      <p>1. 线上流量真实场景租满即送与租满归还比例为3:7；</p>
      <p>2. 只考虑资金在M2开始的逾期率，即每月应收都扣除响应的逾期率资金，不考虑法诉追回资金；</p>
      <p>3. 实际情况中每月发货量各有不同，只能模拟每月固定发货量情况。</p>
    </Card>


  return <div className="computer">
    {component === 'form'
      ? <ParamForm onFinish={onFinish} initValues={values} />
      : <ComputerResult
        dataSource={getTableData(result)}
        totalData={pick(result, totalKeys)}
        paramData={result.params}
        keys={totalKeys} />}
    <Footer />
  </div>
}